"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./../js/bootstrap/bootstrap");
require("./../css/bootstrap.scss");
//import BackgroundCheck from "./../js/background-check/background-check";
require("./components/cookies");
require("./components/forms");
$(function () {
    var header = document.querySelector("#js-header");
    var isNavigationOpen = false;
    $(".nav-button").on("click", function (event) {
        event.preventDefault();
        $(".hamburger").toggleClass("is-active");
        $(".nav-items").toggleClass("open");
        $(".menu-text").toggleClass("hide");
        $("body").toggleClass("disableScroll");
        if (!checkResizeHeader() && hasElementClass(header, "smaller")) {
            header === null || header === void 0 ? void 0 : header.classList.remove("smaller");
        }
        else if (!checkResizeHeader() && !hasElementClass(header, "smaller")) {
            header === null || header === void 0 ? void 0 : header.classList.add("smaller");
        }
        isNavigationOpen = $(".nav-items").hasClass("open");
        if (isNavigationOpen) {
            header === null || header === void 0 ? void 0 : header.classList.add("open");
        }
        else {
            header === null || header === void 0 ? void 0 : header.classList.remove("open");
        }
    });
    $(".go-up a").on("click", function (event) {
        var _a, _b, _c;
        event.preventDefault();
        var idToScrollTo = (_a = $(this).attr("href")) !== null && _a !== void 0 ? _a : "#top";
        var scrollOffset = (_c = (_b = $(idToScrollTo).offset()) === null || _b === void 0 ? void 0 : _b.top) !== null && _c !== void 0 ? _c : 0;
        $("html,body").animate({ scrollTop: scrollOffset }, "fast");
    });
    function resizeHeaderOnScroll() {
        if (!isNavigationOpen) {
            header === null || header === void 0 ? void 0 : header.classList.remove("open");
            if (checkResizeHeader()) {
                header === null || header === void 0 ? void 0 : header.classList.add("smaller");
            }
            else {
                header === null || header === void 0 ? void 0 : header.classList.remove("smaller");
            }
        }
        else {
            header === null || header === void 0 ? void 0 : header.classList.add("open");
        }
    }
    function checkResizeHeader() {
        var distanceY = window.pageYOffset || document.documentElement.scrollTop, shrinkOn = 60;
        return distanceY > shrinkOn;
    }
    window.addEventListener("scroll", resizeHeaderOnScroll);
    document.addEventListener("scroll", function () {
        var _a;
        var scrollTop = document.documentElement["scrollTop"] || document.body["scrollTop"];
        var scrollBottom = (document.documentElement["scrollHeight"] || document.body["scrollHeight"]) - document.documentElement.clientHeight;
        var scrollPercent = (scrollTop / scrollBottom) * 100 + "%";
        (_a = document.querySelector("#_progress")) === null || _a === void 0 ? void 0 : _a.setAttribute("style", "\"--scroll:\", " + scrollPercent);
    }, { passive: true });
    if (document.readyState !== "loading") {
        console.log("document is already ready, just execute code here");
        initBackGroundCheck();
    }
    else {
        document.addEventListener("DOMContentLoaded", function () {
            console.log("document was not ready, place code here");
            initBackGroundCheck();
        });
    }
});
function hasElementClass(event, className) {
    return event === null || event === void 0 ? void 0 : event.classList.contains(className);
}
function initBackGroundCheck() {
    console.log("Background check init");
    // BackgroundCheck.init({
    //   targets: ".target",
    //   images: ".background-check",
    //   //debug: true,
    // });
}
