// If you don't have ES6 transpiler or have TypeScript then you could
// use distributed version but will loose module customization
// import 'bootstrap/dist/js/bootstrap';

// If you have ES6 transpiler then you could code below and will be
// able to customize what modules will be included in the build.

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
//import "bootstrap/js/dist/carousel";
//import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
//import 'bootstrap/js/dist/util';
